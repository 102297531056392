.position {
  padding: 2.5em 0;
  background: $grey-f0;
  color: $color-black;

  @include media-tablet {
    padding: 2em 0; }

  &__main {
    position: relative;
    padding-bottom: 7.1875em;
    border-radius: 1.5em;
    background: $white;
    overflow: hidden;

    @include media-tablet {
      padding-bottom: 9.5em; }

    @include media-tablet-h {
      display: flex;
      justify-content: space-between;
      column-gap: 3.75em;
      height: 31.75em;
      padding-bottom: 0; }

    @include media-desktop {
      column-gap: 5em;
      height: 38.375em; }

    @include media-desktop-1600 {
      height: 35.0625em; }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -9.5em;
      width: 32.625em;
      height: 4.8125em;
      background: url(@public/images/position/main-before-mobile.svg) no-repeat;
      z-index: 1;

      @include media-tablet {
        left: 13.5em;
        width: 32.125em;
        height: 4.125em;
        background: url(@public/images/position/main-before-tablet.svg) no-repeat; }

      @include media-tablet-h {
        display: none; } }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -9.5em;
      width: 32.625em;
      height: 6.6875em;
      background: url(@public/images/position/main-after-mobile.svg) no-repeat;
      z-index: 2;

      @include media-tablet {
        left: -11.5em;
        width: 47em;
        height: 7.9375em;
        background: url(@public/images/position/main-after-tablet.svg) no-repeat; }

      @include media-tablet-h {
        display: none; } } }

  &__left {
    padding: 1em 1em 0;

    @include media-tablet {
      padding: 1.5em 1.5em 0; }

    @include media-tablet-h {
      padding: 2em 0 2em 2em; }

    @include media-desktop {
      padding: 3.125em 0 3.125em 2.5em; }

    @include media-desktop-1440 {
      padding: 3.125em 0 3.125em 3.125em; } }

  &__title {
    @include h24;
    margin-bottom: 1.5rem;
    text-transform: uppercase;

    @include media-tablet {
      @include h36; }

    @include media-tablet-h {
      width: 18.4375rem; }

    @include media-desktop {
      @include h48;
      width: 28rem; }

    @include media-desktop-1600 {
      width: 100%;
      margin-bottom: 2rem; } }

  &__desc {
    @include media-tablet-h {
      max-width: 39.375em; }

    p {
      @include p14;
      margin: 0 0 1rem;

      @include media-tablet {
        @include p18; }

      @include media-tablet-h {
        @include fs18; }

      @include media-desktop {
        @include fs24; }

      @include media-desktop-1600 {
        margin-bottom: 1.5rem; } }

    ul {
      @include p14;
      margin: 0;
      padding-left: 1.375rem;

      @include media-tablet {
        @include p16; }

      @include media-tablet-h {
        max-width: 34.875rem; }

      @include media-desktop {
        @include p18; }

      li:not(:last-child) {
        margin-bottom: 0.5rem;

        @include media-desktop {
          margin-bottom: 0.75rem; } } } }

  &__right {
    position: relative;
    display: none;

    @include media-tablet-h {
      flex: 0 0 25.625em;
      display: block; }

    @include media-desktop {
      flex-basis: 26.1875em; }

    @include media-desktop-1440 {
      flex-basis: 33.6875em; }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      right: -7.625em;
      width: 39.125em;
      height: 16em;
      background: url(@public/images/position/right-before-tablet-h.svg) no-repeat;
      z-index: 1;

      @include media-desktop {
        width: 42.625em;
        height: 18.8125em;
        background: url(@public/images/position/right-before-dekstop.svg) no-repeat; }

      @include media-desktop-1440 {
        right: 0; } } }

  &__img {
    position: relative;
    display: block;
    z-index: 2; } }
