.footer {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  overflow: hidden;
  color: $white;
  background-color: $dark-grey;

  .cont {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include media-tablet-h {
      padding-left: 1.875em;
      padding-right: 1.875em; }

    @include media-desktop-1440 {
      flex-wrap: nowrap; }

    @include media-desktop-1600 {
      padding-left: 2.5em;
      padding-right: 2.5em; } }

  &__logo {
    position: relative;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-bottom: 1.25em;
    margin-bottom: 2.5em;
    border-bottom: 1px solid $grey-80;

    @include media-tablet {
      margin-bottom: 2em;
      padding-bottom: 0;
      border-bottom: none;

      &:after {
        content: '';
        display: inline-block;
        vertical-align: middle;
        height: 1px;
        width: 100%;
        margin-left: 4.5625em;
        background-color: $grey-80; } }

    @include media-desktop {
      margin-bottom: 2.5em; }

    @include media-desktop-1440 {
      max-width: 13.875em;
      padding-right: 3.75em;
      margin-right: 3.75em;
      margin-bottom: 0;
      border-right: 1px solid $grey-80;

      &:after {
        display: none; } }

    @include media-desktop-1600 {
      max-width: 15.125em;
      padding-right: 5em;
      margin-right: 5em; }

    img {
      display: block;
      min-width: 10.125em; } }

  &__content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 2rem 2.5rem;
    font-size: 1em;

    @include media-tablet {
      column-gap: 2rem; }

    @include media-desktop {
      justify-content: space-between;
      flex-wrap: nowrap;
      column-gap: 2.5rem; }

    @include media-desktop-1440 {
      justify-content: space-between; } }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 13.375em;

    @include media-desktop {
      max-width: 11.0625em; }

    &--long {
      @include media-desktop {
        max-width: 13.375em; } }

    &-title {
      @include reset;
      margin-bottom: 1rem;
      font-size: 0.875em;
      color: $green-footer; }

    &-name {
      @include reset;
      font-size: 1em;
      font-weight: 400; }

    &-loyalty {
      white-space: nowrap; }

    a {
      display: inline-block;
      margin-top: 0.25rem;
      font-size: 0.875em;
      color: $white;

      &:hover {
        color: $green-footer; } }

    &-contact-us {
      padding: 0.333rem 1.2rem;
      margin-top: 1.2rem !important;
      border: 1px solid $green-footer;
      border-radius: 0.4rem;
      background-color: transparent;

      &:hover {
        color: $white !important;
        background-color: $green-footer; } }

    &-loyalty {
      &-tg {
        display: inline-block;
        vertical-align: middle;
        width: 1.666em;
        height: 1.666em;
        margin-left: 0.416em;
        background: url(../images/tg_icon_ft.svg) center no-repeat; } } } }
