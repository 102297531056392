.steps {
  padding: 3.75em 0 5em;
  background: $color-black;
  color: $white;

  @include media-tablet {
    padding-top: 4.125em; }

  @include media-desktop-1440 {
    padding-top: 3.75em; }

  &__cont {
    display: flex;
    flex-direction: column;
    row-gap: 2.6875em;

    @include media-tablet {
      row-gap: 3.75em; }

    @include media-tablet-h {
      row-gap: 5em; } }

  &__title {
    @include reset;
    @include h36;

    @include media-tablet {
      @include h48;
      max-width: 34rem; }

    @include media-desktop {
      @include h64; } }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    max-width: 38.375em;

    @include media-tablet {
      row-gap: 2.5em;
      max-width: 36.25em; }

    @include media-tablet-h {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 4.0625em 3.125em;
      max-width: none; }

    @include media-desktop {
      gap: 4.5em 6.25em;
      max-width: 67.8125em; }

    @include media-desktop-1440 {
      gap: 4.375em 2.75em;
      max-width: none; } }

  &__item {
    display: flex;
    align-items: center;
    column-gap: 0.875em;

    @include media-tablet {
      column-gap: 1.25em; }

    @include media-tablet-h {
      width: calc(50% - 1.5625em); }

    @include media-desktop {
      width: calc(50% - 3.125em); }

    @include media-desktop-1440 {
      width: 35em; }

    &:first-child,
    &:last-child {
      .steps__item-desc {
        @include media-desktop-1440 {
          max-width: 21.25rem; } } }

    &-num {
      flex: 0 0 3.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.875rem;
      height: 3.875rem;
      border-radius: 1rem;
      border: 3px solid $color-yellow-new;
      font-size: 2.875em;
      font-weight: 800;
      line-height: 1;
      color: $color-yellow-new;

      @include media-tablet {
        flex-basis: 6.25rem;
        width: 6.25rem;
        height: 6.25rem;
        border-radius: 1.5rem;
        border-width: 4px;
        font-size: 4.375em; }

      @include media-desktop {
        flex-basis: 9.625rem;
        width: 9.625rem;
        height: 9.625rem;
        font-size: 7.3125em; } }

    &-desc {
      @include fs14;
      font-weight: 600;

      @include media-tablet {
        font-size: 1.25em; }

      @include media-desktop-1440 {
        @include fs24; }

      br {
        display: none;

        @include media-tablet-h {
          display: block; } }

      a {
        color: $color-yellow-new;
        font-size: 0.75rem;
        text-decoration-line: underline;
        outline: none;

        @include media-tablet {
          font-size: 1.25rem; }

        @include media-desktop-1440 {
          font-size: 1.5rem; }

        &:hover {
          text-decoration: none; } } } }

  &__note {
    @include fs18;
    font-weight: 600;

    @include media-tablet {
      @include fs24; }

    @include media-tablet-h {
      font-size: 1.75em; }

    @include media-desktop {
      font-size: 1.875em; } } }
